import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
	{ path: "", redirectTo: "home", pathMatch: "full" },
	{ path: "home", loadChildren: "./pages/home/home.module#HomePageModule" },
	{ path: "reset-link", loadChildren: "./pages/reset-link/reset-link.module#ResetLinkPageModule" },

	{ path: "main", loadChildren: "./pages/main/main.module#MainPageModule" },
	{ path: "description", loadChildren: "./pages/description/description.module#DescriptionPageModule" },
	{ path: "order/:order_type/:order_id", loadChildren: "./pages/order/order.module#OrderPageModule" },
	{ path: "orders/:order_type", loadChildren: "./pages/orders/orders.module#OrdersPageModule" },
	{ path: "items/:order_type/:order_id", loadChildren: "./pages/items/items.module#ItemsPageModule" }
];

@NgModule( {
	imports: [ RouterModule.forRoot( routes ) ],
	exports: [ RouterModule ]
} )
export class AppRoutingModule { }
